/**
 * We'll load the Lodash.
 */

// window._ = require('lodash');

/**
 * We'll load the Alpine.
 */

import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()

/**
 * We'll load the Quill wysiwyg editor.
 */

window.Quill = require('quill');

require('./quill');

/**
 * We'll load the Bootstrap plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

require('bootstrap');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// window.axios = require('axios');

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * We'll load the jQuery library.
 */

window.jQuery = require('jquery');

require('./form');

require('./string');

require('./recaptcha');

/**
 * We'll load the Select2.
 */

window.select2 = require('select2');

require('./select2');

/**
 * We'll load the bootstrap custom file input.
 */

window.bsCustomFileInput = require('bs-custom-file-input');

/**
 * We'll load the easytimer.js.
 */

window.Timer = require('easytimer.js').Timer;

 require('./easytimer');

 /**
 * We'll load the chart.js.
 */

window.Chart = require('chart.js/dist/chart');

require('./chart.js');

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
