function getConfig(el) {
  return {
    type: el.dataset.type,
    data: JSON.parse(el.dataset.data),
    options: {
      indexAxis: el.dataset.indexaxis ?? 'x',
      plugins: {
        title: {
          display: true,
          text: el.dataset.title
        }
      }
    }
  }
}

[].slice.call(document.querySelectorAll('.chart')).map(function(el) {
  return new Chart(el, getConfig(el));
});
