(function ($) {

  // Remove empty fields from GET forms
  $('form').on('submit', function(event) {
    $(this)
      .find(':input')
      .not('button')
      .filter(function() { return ! this.value })
      .attr('disabled','disabled');
    
    return true;
  });

  // Show model for confirm before delete
  $('#delete, #option').on('show.bs.modal', function(event) {
    var action = $(event.relatedTarget).data('action');

    $(this).find('form').attr('action', action);
  });

  $(function() {
    // Bootstrap Custom Input File
    bsCustomFileInput.init();
  });

})(jQuery);
