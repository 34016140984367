(function ($) {
  
  const timerContainer = $('#timer');

  if ( ! timerContainer.length) {
    return;
  }

  const timer = new Timer();

  const timerDays = $('#timer .days');
  const timerHours = $('#timer .hours');
  const timerMinutes = $('#timer .minutes');
  const timerSeconds = $('#timer .seconds');

  function secondsUpdated() {
    timerDays.html(timer.getTimeValues().days);
    timerHours.html(timer.getTimeValues().hours);
    timerMinutes.html(timer.getTimeValues().minutes);
    timerSeconds.html(timer.getTimeValues().seconds);
  }

  function targetAchieved() {
    location.reload();
  }

  timer.start({
    countdown: timerContainer.data('countdown') ?? false, 
    startValues: timerContainer.data('start') ?? {},
  });

  timer.addEventListener("secondsUpdated", secondsUpdated);

  timer.addEventListener('targetAchieved', targetAchieved);

  secondsUpdated();

})(jQuery);
