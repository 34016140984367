const Inline = Quill.import('blots/inline');
const Icons = Quill.import('ui/icons');

const COLORS = [
  "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
  "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
  "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
  "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
  "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
  '#2775b1',
];

Icons['anchor'] = '<svg viewbox="0 0 18 18"><line class="ql-stroke" x1="7" x2="6" y1="3" y2="15"></line><line class="ql-stroke" x1="12" x2="11" y1="3" y2="15"></line><line class="ql-stroke" x1="3.75" x2="14.75" y1="7" y2="7"></line><line class="ql-stroke" x1="3.25" x2="14.25" y1="11" y2="11"></line></svg>';

class AnchorBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('id', value);
    return node;
  }

  static formats(node) {
    return node.getAttribute('id');
  }
}

AnchorBlot.blotName = 'anchor';
AnchorBlot.tagName = 'SPAN';

Quill.register(AnchorBlot);

const anchorHandler = function(value) {
  if (value) {
    const range = this.quill.getSelection();
    if (range == null || range.length == 0) return;
    const preview = prompt('Enter anchor');
    this.quill.format('anchor', preview);
  } else {
    this.quill.format('anchor', false);
  }
}

const fullToolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block', 'code'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  [{ 'direction': 'rtl' }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video', 'formula'],
  ['clean']
];

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': COLORS }, { 'background': [] }],
  [{ 'align': [] }],
  ['link'],
  ['clean'],
  ['anchor']
];

[].slice.call(document.querySelectorAll('.editor')).map(function(el) {
  return new Quill(el, {
    modules: {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          'anchor': anchorHandler
        }
      }
    },
    theme: 'snow'
  });
})
