(function ($) {
  const form = document.querySelector('.recaptcha');

  if (form !== null) {
    form.addEventListener('submit', getRecaptchaToken);
  }

  function getRecaptchaToken(event) {
    event.preventDefault();

    grecaptcha.ready(function() {
      grecaptcha.execute(document.querySelector('meta[name="g-recaptcha-site-key"]').content, {action: 'registration'}).then(function(token) {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', 'g-recaptcha-response');
        input.setAttribute('value', token);

        form.appendChild(input);
        form.submit();
      });
    });
  }
})(jQuery);
