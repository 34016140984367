(function ($) {

  /**
   * Initinal Select2
   * 
   * @param {string} selector 
   * @param {object} options 
   */
  
   window.select2Initial = function select2Initial(selector = 'select.select2', options = {}) {
    $(selector).each(function() {
      $(this).select2($.extend({}, {
        theme: 'bootstrap4',
        width: 'style',
        placeholder: $(this).attr('placeholder') ? $(this).attr('placeholder') : '',
        allowClear: Boolean($(this).data('allow-clear')),
        closeOnSelect: !$(this).attr('multiple'),
      }, options));
    });
  }

  $('.modal').on('show.bs.modal', function() {
    select2Initial($(this).find('select.select2'), {
      dropdownParent: $(this)
    });
  });

  select2Initial();

})(jQuery);
